import React from 'react';
import SEO from '../../components/seo';
import MissionMuseumPageEN from '../../components/desktop/MissionMuseumPage/MissionMuseumPageEN';

const About = () => {
  return (
    <>
      <SEO title={"Museum's mission"} />
      <MissionMuseumPageEN />
    </>
  );
};

export default About;
