import React from 'react';
import {
  ColorWrapper,
  Container,
  PageContainer,
  ImageWrapper,
  TitlePage,
  TitlePageEN,
} from './styles';
import {Parallax} from 'react-scroll-parallax';
import bg from './images/background.jpg';
import Gallery from '../Gallery/Gallery';

const images = [
  {src: require('./images/01.jpg'), sign: 'Pierwsze spotkanie zespołu'},
  {
    src: require('./images/02.jpg'),
    sign: 'Wizyta telewizji podczas prac digitalizacyjnych',
  },
  {src: require('./images/03.jpg'), sign: 'Digitalizacja rzeźb'},
  {src: require('./images/04.jpg'), sign: 'Przygotowanie rzeźby do skanu 3D'},
  {
    src: require('./images/05.jpg'),
    sign: 'Studio fotograficzne w pracownii Jana Szczepkowskiego',
  },
  {
    src: require('./images/06.jpg'),
    sign: 'Prelekcja na Konferencji „Kultura w 3D – 3D w kulturze"',
  },
];

const MissionMuseumPage = () => {
  return (
    <PageContainer backgroundColor={'#a3c0c0'} style={{paddingBottom: 0}}>
      <Parallax y={[20, -20]}>
        <ImageWrapper srcBg={bg} />
      </Parallax>
      <Parallax y={[8, -160]} className="second-parallax">
        <ColorWrapper padding bgColor="#525252">
          <TitlePageEN>Museum’s mission</TitlePageEN>
          <Container>
            <p
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '0.83rem',
                lineHeight: '1.8',
                textAlign: 'justify',
                color: '#ffffff',
              }}>
              &emsp;&emsp;The Virtual Museum of Jan Szczepkowski had its
              beginning in 2020, thanks to the grant the Cultural Center of
              Milanówek received from the Ministry of Culture and National
              Heritage “Kultura Cyfrowa 2020” program.
              <br />
              <br />
              &emsp;&emsp;Our mission is to popularize the life’s work of one of
              the most outstanding art representatives of the Second Polish
              Republic, Jan Szczepkowski. In recent years his legacy has been
              forgotten, his works completely unavailable. We wanted to change
              that by creating a platform allowing everyone to learn his story
              everywhere and at any time. What’s more, we want to emphasize how
              important it is to protect the monuments. We want to preserve the
              past using new technologies. The virtual exhibition is just the
              beginning of the process meant to protect our heritage from
              disappearing. We believe we are creating an opportunity for future
              generations to learn new ways of gaining knowledge, promoting and
              sharing art.
              <br />
              <br />
              &emsp;&emsp; So here it is, welcome! We invite you to visit our
              virtual museum, without the need to wait in a queue for a ticket
              or even having to pay for it! Here you have unlimited access to
              all of Szczepkowski’s works we managed to digitize and write
              about. Enjoy your time here and stay tuned for more things we have
              in store for the future of our Museum!
            </p>
            <Gallery colorSign={'#ffffff'} images={images} />
          </Container>
        </ColorWrapper>
      </Parallax>
    </PageContainer>
  );
};

export default MissionMuseumPage;
